import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const token = cookies.get('token')
export const axiosJWT = axios.create({
  headers: {
    Authorization: `${token}`,
  },
})
